.page-footer{
    background: url('./cover.png') !important;
    background-size: cover !important;
    background-repeat: no-repeat;
}

.containerMy{

    padding:64px 80px 64px 80px;
}
.logowhite{

    max-width: 100%;
}
.links li i{
    margin-right: 40px;
    color:#656061;
}
.links li {
   margin-bottom: 24px;
}
.links li a{

    font-size: max(13px, 1.1vw);
    font-weight: 300;
    color:rgb(243, 243, 243);
    transition: 0.5s;
}
.links li .fa-circle{
    color:red;
    font-size:10px;
}

.links{
    padding-top:16px
}
.links li a:hover{
    background-color: transparent !important;
    color:#D61724;
    font-weight: 600;
}
.box-title-barra{
    display: flex;
    justify-content: center;
    align-items: center;
    
}
.barra-2{
    width: 70px;
    border:none;
    height: 4px;
    background-color: #D61724;
    margin-top: 12px;
   
}
.box-1{
    padding-left: 64px !important;
}
.title-footer{
    margin-left: 50px;
    color:#fff;
    font-weight: 500;
    font-size: 1.8em;
    line-height: 40px;
}
.link-midia a i{

    height: 45px;
    width: 45px;
    background-color: #D61724;
    margin: 8px;
    border-radius: 6px;
    font-size: 1.5em;
    padding: 12px;
    color:#fff;
    transition: 1s;
   

} 
.link-midia a i:hover{
    background-color:#fff;
    color: #D61724;
}
.link-midia{

    padding-left: 40px;
}
.copyright{
    padding:6px;
    background-color: #1C1513;
    color:#C8C1BF;
    font-weight: 300;
}

.foonter-map{

    display: flex;
    justify-content: space-between;
}

@media all and (max-width:500px) {

    .containerMy{

        padding:64px 32px 64px 32px;
    }
    .box-1{
        padding-left: 0 !important;
    }
    .links li i{
        margin-right: 24px;
       
    }
    .title-footer{
        margin-left: 12px;
       
    }
    
}


@media all and (max-width:327px) {

    .containerMy{

        padding:64px 24px 64px 24px;
    }
    .box-1{
        padding-left: 0 !important;
    }
    .links li i{
        margin-right: 12px;
       
    }
    .title-footer{
        margin-left: 12px;
       
    }
    
}