.box-contacto{
    margin-top: 88px;
}

.contacto .section-right{
    
    background-color: #201714;
    height: 828px;
    overflow: hidden;
    z-index: -1;
}
.contacto .section-into{

    margin-top: 148px;
    position: absolute;
    padding-left: 0 !important;
}

.contacto .cover{
    top:0;
}

.contacto form .input-field input{

    background-color: #f1f1f1 !important;
    border-radius: 0 !important;
   

}

.contacto form textarea{

    background-color: #f1f1f1 !important;
    border-radius: 0 !important;

}


.box-adress{

    margin-top: 140px;
    position: relative;
}

@media all and (max-width:500px) {


    .contacto .section-into{

        margin-top: 16px;
        
    }

    .contacto .box-desc{
        margin-top: 64px;
    }

    .contacto .section-right{
    
      
        height: 620px;
      
    }
  
    .contacto .section-into{
        height: auto;
    }
}

@media all and (max-width:340px) {

    .contacto .detalhe-into{
        font-size:max(2.5rem,3.2vw);
    }

    .contacto .box-desc{
        margin-top: 96px;
    }
   
  
}

@media all and (max-width:327px) {


    
  
}