html, body{
    overflow-x: hidden !important;
}
body{
    max-width: 100%;
    background-color: #F1F1F1;
    scroll-behavior: smooth;
}
.reset{

    padding-left: 80px !important;
    padding-right: 80px !important;
}

@font-face {
    font-family: LatoBold;
    src: url('./fontes/lato/Lato-Bold.ttf');
}

@font-face {
    font-family: Raleway;
    src: url('./fontes/raleway/Raleway-Medium.ttf');
}



@media all and (max-width:500px) {

    .reset{
        padding-left: 24px !important;
        padding-right: 24px !important;
    }



   
}
