.sobre .section-right{
    
    background-color: #201714;
    height: 1400px !important;
    overflow: hidden;
    z-index: -1;
}
.sobre-section .section-into{

    margin-top: 148px;
    position: absolute;
    padding-left: 0 !important;
}
.sobre .cover{
    top:0;
}
.sobre .woman-world{

    width: 400px;
    margin-top: 860px;
}
.sobre .describe-section{

    font-size: max(1.2vw, 1.1rem);
    line-height: 32px;

}
.sobre .picture-03{

    width: 500px;
}
.sobre .section-02{
    margin-top: 42px;
}
.senior-img{

    max-width: 100%;
    width: 600px;
    left: 0;
    bottom:-980px;
    position: absolute;
}

/*
.sobre .section-02{

   
   
}
.sobre .box-content{

  
} */
.sobre .p-desc{
    font-size: max(1.02vw, 1.1rem);
}
.card-onganigrama{

    padding-top: 76px !important;
    margin-top: 32px;
}
.organograma{

    max-width: 100%;
    margin-top: 32px;
    margin-bottom: 108px;
}

@media all and (max-width:500px) {


    .sobre .section-into{

        margin-top: 0px;
        height: 600px;
        
    }

    .sobre .box-desc{
        margin-top: 64px;
    }

    .sobre .section-right{
    
      
        height: auto !important;
        
    }
  
  

    .sobre .woman-world{

        margin-top: 80px;
    }

    .sobre .section-left{

        margin-top: 64px;
        
    }

    
}

@media all and (max-width:340px) {

    .sobre .detalhe-into{
        font-size:max(2.6rem,3.2vw);
    }

    .sobre .box-desc{
        margin-top: 80px;
    }
   
  
}

@media all and (max-width:327px) {


    
  
}