.post .section-right{
    
    background-color: #201714;
    height: 828px;
    overflow: hidden;
    z-index: -1;
}
.post .section-into{

    margin-top: 148px;
    position: absolute;
    padding-left: 0 !important;
}

.post .cover{
    top:0;
}
.box-news{

    margin-top: 100px;

}

.box-list-filter{
    display: flex;
    justify-content: center;
    gap:16px;
    flex-wrap: wrap;
    z-index: 9999;
}
.box-list-filter button{
    border:none;
    width: 180px;
    flex-basis: 180px;
    height:55px;
    font-size: 16px;
    font-weight: 500 !important;
    background-color: #f1f1f1;
    transition: .5s ease-in-out !important;
}

.btn-filter-selected{
    background-color: #C51F29 !important;
    color:#fff !important;
}

/*  ----------------btn year ------------ */
.title-header{
    font-size:32px;
    font-family: LatoBold;
}

.box-options-year{
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 24px;
    padding-bottom: 16px;
    gap:16px;
    flex-wrap: wrap;

}
.btn-year{
    border:none;
    width: 150px;
    flex-basis: 140px;
    height:45px;
    font-size: 16px;
    font-weight: 500 !important;
    background-color: #f1f1f1;
    transition: .5s ease-in-out !important;
}

.btn-selected{
    background-color: #C51F29 !important;
    color:#fff !important;
}

/*-----------------*/
.img-news{
    max-width: 100%;
    height: 600px;
    width: 100%;
    object-fit: cover;
}
.news-img{

    position: relative;
}
.box-cat{
    background-color: #C51F29;
    height: 55px;
    width: 250px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top:80px;
    left: -30px;
}

.data-post{

    color:#28201D;
    font-size: 1.2em;
}
.data-post i{

    margin-right: 16px;
}
.title-post-p{

    color:#000;
    font-size: 2.2em;
    font-weight: bold;
    margin-bottom: 2px;
    margin-top: 6px;
}
.conteudo{

    font-size:max(16px,1.3vw);
    line-height: 38px;
    margin-bottom: 88px;
   
}

.box-categoria button{

    width: 100%;
    height: 70px;
    border: none;
    background-color: #f1f1f1;
    color:#312A27;
    margin-bottom: 24px;
    transition: 1s;
}

.box-categoria button:hover{

    background-color: #C51F29;
    color:#fff;
}
.post .card-post{

    margin-bottom: 48px;
}

.post .post-cover{
    max-width: 100%;
    width: 100%;
    height: 350px;
    object-fit: cover;
    filter: grayscale(100%);
    transition: 1s;
   
}

.post .limite{

    transition: 0.5s;
   position: relative;
   height: 210px;
   margin-bottom: 64px;
}

.post .det-post{

    height: 130px;
    bottom: -140px;
    width:100%;
    left:0;
    border-radius: 0;
}
.post .det-post:hover{

    height: 140px;
}
.link-read{
    color:#b61b26;
    font-size: 16px;
    font-weight: 400;
    display: block;
    margin-top: 4px;
    cursor: pointer;
}
.video-icone{
    height: 70px;
    background-color: #C51F29;
    width: 70px;
    border-radius: 50%;
    position: absolute;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    margin-top: auto;
    margin-bottom: auto;
    top:0;
    bottom: 0;
    font-size: 20px;
    color:#fff;
    cursor: pointer;
    transition: 1s;
}
.video-icone i{

    transition: 1s;
    border-radius: 50%;

}
.video-icone:hover {

    transform: scale(1.1);
}
.video-icone:hover i{

    height: 55px;
    width: 55px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color:#C51F29;
    background-color: rgb(255, 255, 255);
}
.alert-notice{

    background-color: #f1f1f1;
   padding: 48px;
   font-size: 2rem !important;
   clip-path: circle();
   color:#C51F29;
}



@media all and (max-width:500px) {


    .post .section-into{

        margin-top: 16px;
        
    }

    .post .box-desc{
        margin-top: 64px;
    }

    .post .section-right{
    
      
        height: 620px;
      
    }
  
    .post .section-into{
        height: auto;
    }

    .post .det-post .title-post{

        font-size: 16px;
    }

   .post .det-post{

      
        background-color:rgba(255, 255, 255, 0.753);
        width:95%;
        position: absolute;
        left: 8px;
        right: 2px;
      
       
    }
    .post .card-post{
        padding-left:0 !important;
        padding-right:0 !important;
     
    }

    .post .post-cover{
      
        height: 300px;
       
    }
    
    .post .limite{
       height: 150px;
    }
}

@media all and (max-width:340px) {

    .post .detalhe-into{
        font-size:max(2.5rem,3.2vw);
    }

    .post .box-desc{
        margin-top: 96px;
    }

   
  
}

@media all and (max-width:327px) {


    
  
}