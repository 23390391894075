.servico .section-right{
    
    background-color: #201714;
    height: 828px;
    overflow: hidden;
    z-index: -1;
}


.servico .section-into{

    margin-top: 148px;
    position: absolute;
    padding-left: 0 !important;
}

.servico .cover{
    top:0;
}
.box-service-s{

    padding-top: 88px;
    position: relative !important;
}
.second-grid{

    position:absolute;
    max-width: 100%;
    width: 120px;
    right: 50px;
}
.img-service{
    max-width: 100%;
    width: 90px;
}
.card-servico-s{

    background-color: #f1f1f1;
    height: 620px;
    padding:40px 32px 32px 32px;
    margin-top: 88px;
    transition: 1s;
}
.card-center{

    height: 700px;
    margin-top: 45px;
}
.card-servico-s:hover{

    background-color: #201714;
    transform: scale(1.05);

}
.desc-servico{

    font-size:max(14px,1.1vw);
    line-height: 30px;
    color:#000;
}

.card-servico-s:hover .desc-servico, 
.card-servico-s:hover .title,
.card-servico-s:hover li{

    color:#fff;

}

.list-service  {

    padding-left: 32px;
    margin-top: 32px;
}
.list-service li{

    margin-bottom: 12px;
    font-size: max(14px,1.1vw);
    color:#000;
}



.list-service li i{

    margin-right: 24px;
    font-size: 10px;
}
.t-s{

    margin-bottom: 56px;
}

/*======= RESPONSIVIDADE ===========*/



@media all and (max-width:500px) {

    .card-servico-s{

      
        margin-top: 0px;
      
    }

    

    .servico .section-into{

        margin-top: 16px;
        height: 300px;
      
    }

    .servico .box-into{
        
        margin-top: 40px;
    }
    .servico .box-service-s .title-section{

        font-size: 2rem;
    }
  

    .servico .section-right{

        height: 560px;
     
     
    }

    .servico .detalhe-into{

        font-size: 2.3rem;

    }

    .servico  .t-s{

        font-size: 1.5rem;

    }

    .card-servico-s{

     
        padding:40px 24px 32px 24px;
      
    }

    .list-service  {

        padding-left: 12px;
        margin-top: 16px;
    }

    .list-service li i{

        margin-right: 16px;
        font-size: 8px;
    }

    .servico .box-service-s{

        padding-left: 12px !important;
        padding-right: 12px !important;
    }

    .card-servico-s .p-icone{

        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 32px;
    }
  
}

@media all and (max-width:360px) {

   

    .servico .detalhe-into{

        font-size: 2.2rem;

    }
  
   

  
}

@media all and (max-width:340px) {

    .servico .box-service-s{

        padding-left: 2px !important;
        padding-right: 2px !important;
    }

    .service-section{

        padding: 0 !important;
    }

    .servico .detalhe-into{

        font-size: 2.1rem;

    }
    .servico .detalhe-into-2{

        font-size: 15px ;
    }

    .servico  .t-s{

        font-size: 1.5rem;

    }
   

  
}

@media all and (max-width:327px) {

    .servico .detalhe-into{

        font-size: 2.2rem;

    }

    .servico .section-right{

        height: 600px;
     
     
    }
    .servico .detalhe-into-2{

        font-size: 15px ;
    }

    .servico  .t-s{

        font-size: 1.5rem;

    }

    .servico .box-service-s{

        padding-left: 2px !important;
        padding-right: 2px !important;
    }

    .service-section{

        padding: 0 !important;
    }
   

  
}