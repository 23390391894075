.gestao .section-right{
    
    background-color: #201714;
    height: 1500px;
    overflow: hidden;
    z-index: -1;
}
.gestao .section-into{

    margin-top: 148px;
    position: absolute;
    padding-left: 0 !important;
}
.gestao .cover{
    top:0;
}

.gestao .man-bussines{
    max-width: 100%;
    width: 450px;
    margin-top: 1050px;
    
}
.gestao .icone-info{

    width: 300px;
    margin-top: 16px;
}
.gestao .describe-section{

    margin-top: 64px;
}
.first-fundo{
    margin-top: 100px;
}
.company{
    max-width: 100%;
    width: 210px;
    margin-top: 64px;
}
.title-fundo{

    font-size: 1.5em;
    color:#000;
    font-weight: 600;
}
.box-desc-first{
    margin-top: 64px;
    position: relative;
    margin-bottom: 64px;
}

.desc-fundo{
    font-size: max(1.1vw, 16px);
    line-height: 30px;
    margin-bottom: 48px;
}
.desc-fundo-2{
    font-size: max(1.03vw, 1rem);
    line-height: 28px;
    margin-top: 0;
}
.icone-fundo{
    max-width: 100%;
    width: 40px;
    margin-top: 8px;
}
.box-desc-fundo{

    padding-left: 10px !important;
    margin-bottom: 16px;
}
.box-desc-fundo .title i{

    margin-right: 16px;
}
.box-first{

    padding-bottom: 88px !important;
}
.c-fundo{

    background-color: #f1f1f1;
    height: 300px;
    padding:24px;
    transition: 0.5s all ease-in-out;
}
.c-fundo:hover{

    color:#fff !important;
    background-color: #201714 !important;
    transform: scale(1.05);
}

.c-fundo:hover .title{

    color:#fff !important;
}
.p-1{
    top:-190px;
    right: 0;
}
.position{

    font-size: 5rem;
    color:#000;
    font-weight: bold;
    position: absolute;
    border-bottom: 6px solid #C51F29; 
}

.p-2{
    left: 0;
    top:-170px
   
}
.second{

    margin-top: 32px;
    position: relative;
    margin-bottom: 88px;
}
.first-grid{

    position: absolute;
    right: 0;
    max-width: 100%;
    width: 80px;
    top: 70px;
   
}
.three{

    margin: 100px 0 150px 0;
    padding-top: 88px;
    
}
.three-box{

    height: 340px;
    background-color: #f1f1f1;
    padding: 48px 32px 48px 32px;
    position: relative;
    transition: 1s;
}
.three-box:hover{
    background-color: #201714;
    color:#fff;
    transform: scale(1.05);
}
.three-box:hover .title-fundo{

    color:#fff
}
.three .title-fundo{
    margin-top: 0;
    line-height: 36px;
}
.three .desc-fundo{
    margin-bottom: 0;
}
.p-3{

    right: 30px;
    top:-140px;
}
.btn-acessar{
    height: 55px;
    width: 190px;
    margin-top: 48px;
    border: none;
    background-color: #C51F29;
    color:#fff;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
}
.btn-acessar:hover{

    background-color: #fff;
    color:#C51F29;
}
.plano{
    
    padding-left: 0 !important;
}

.box-more-info{

    margin-top: 24px;
   
}


.box-more-info div{

    margin-top: 48px;
    border:1px solid #201714;
    padding:16px 32px 8px 32px;
   
}
.direitos li{

    margin-bottom: 7px;
   

}
.bg-white{
    background-color: #fff;
}

.box-center{
    background-color: #fff !important;
    height: 250px;
    margin-top: -64px;
    margin-right: 8px;
    box-shadow: -4px 0 24px rgba(58, 58, 58, 0.075);
    border-radius: 6px;
    padding: 8px 40px !important;
    
}

.box-center-2{
    background-color: #fff !important;
    height: 250px;
    margin-top: -64px;
    box-shadow: 4px 0 24px rgba(58, 58, 58, 0.075);
    border-radius: 6px;
    padding: 8px 40px !important;
}

.box-info-center{
    background-color: teal;
}
.center-title{
    font-size: 20px;
    text-transform: uppercase;
    font-weight: 500;
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: -2px;
    border-bottom: 1px solid #b9b9b9;
    padding-bottom: 8px;
}

.title-goal{
    font-weight: 600;
}

.title-goal span{
    font-weight: normal;
}


@media all and (max-width:500px) {

    .box-center-2{
        margin-top: 12px;
    }

    .gestao .section-into{

        margin-top: 0px;
        height: 620px;
        
    }

    .gestao .section-left{
        margin-top: 660px;
    }

    .gestao .box-desc{
        margin-top: 64px;
    
    }

    .gestao .describe-section{

        margin-top: 0px;
    }

    .gestao .section-right{
    
       
        height: auto !important;
       
    }

    .gestao .man-bussines{
    
        margin-top: 100px;
        
    }

    .c-fundo{

       background-color: transparent;
    }
   

    
    .three{

        margin: 40px 0 150px 0;
    
    }

    .sobre .section-right{
    
      
        height: auto !important;
        
    }

    .box-desc-first{
        margin-top: 40px;
        margin-bottom: 16px;
        
    }

    .first-fundo{
        margin-top: 24px;
    }
  
    .three-box{

        height: 340px;
        background-color: #f1f1f1;
        padding: 48px 32px 48px 32px;
        position: relative;
        transition: 1s;
        margin-bottom: 88px;
    }

  

    .padding-zero{
        padding-left: 0 !important;
        padding-right: 0 !important;
    }

    .box-more-info div{

        margin-top: 0px;
        border:none;
        padding:12px;
       
    }

    
}



@media all and (max-width:350px) {

    .detalhe-into{
        font-size:2.2rem;
    }

    .detalhe-into-2{

      
        font-size: max(1.1rem,1.3vw);
        line-height: 26px;
      
    
    }

    .title-fundo{

        font-size: 1.3em;
        color:#000;
        font-weight: 600;
    }

    .padding-zero{
        padding-left: 0 !important;
        padding-right: 0 !important;
    }

    .c-fundo{

      
        height: auto;
        background: transparent;
        padding:8px 0px 16px 0px;
      
    }

    .box-more-info div{

        margin-top: 0px;
        border:none;
        padding:12px;
       
    }

    .btn-acessar{
       
        margin-top: 32px;
       
    }
   
  
}

@media all and (max-width:327px) {


    
  
}