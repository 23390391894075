nav{
    position: absolute;
    padding-top: 40px;
    height: 150px !important;
    z-index: 1;
    background-color: #201714 !important;
}
.link-sobre{
    margin-right: 96px;
}
.btn-lang{

    color: #fff !important;
    background-color: transparent !important;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 8px;
    
    height: 45px;
    border:none;
    padding: 24px;
    padding-right: 0;
    position: relative;
   
}
.btn-lang button{
    margin-right: 16px;
  
    height: 40px !important;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    border: none;
    position: absolute;
   
   
   
}
.en{
    color: #fff;
    background-color: #2b2929 !important;
   
}
.pt{
    margin-right:24px;
    background-color: #C51F29 !important;
}
.en-opc{
    margin-left: 24px;
}
.btn-lang button:hover{

    transform: scale(1) !important;
}
.logo{
    max-width: 100%;
    width: 180px;
}
ul li a:hover{
    color: #fff !important;
    font-weight: 600;
}
.btn-lang:hover{
    color: #fff !important;
    font-weight: normal;
}

.active-link{
    transition: 0.7s all ease-in-out;
    background-color: #C51F29;
    
}

.btn-open-close{
    width: 50px;
    height: 50px;
    border:none;
    background-color: #C51F29 !important;
    color:#201714;
    margin-top: 2px;
  
}

.menu-mobile{

   
    position: absolute;
    width: 230px;
    right: 0;
    margin-top: 64px;
    padding-left: 0px !important;
    background-color: #201714;
    transition: 1s all ease-in-out;
}
.hide-menu{

    height: 0px;
    overflow: hidden;

}
.show-menu{
    height: 450px;
}
.link-mobile{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    padding: 24px;
}



/*.list-link{
    background-color: #201714;
    padding: 12px;
}*/


@media all and (max-width:500px) {

    .logo{
        max-width: 100%;
        width: 150px;

    }
   
    nav{
        position: relative;
        padding-top: 32px;
        height: 100px !important;
        z-index: 1111;
        background-color: #201714 !important;
    }
}
/*
@media all and (max-width:1500px) {

    .link-sobre{
        margin-right: 18vh;
    }
    
}*/

