.section-right{
    
    background-color: #201714;
    height: 1600px;
    overflow: hidden;
    z-index: -1;
}
.btn-up{
    height: 55px;
    width: 55px;
    background-color: #C51F29 !important;
    position: fixed;
    z-index: 999;
    bottom:30px;
    right: 60px;
    border: none;
    color:#f1f1f1;
    border-radius: 50%;
    animation: pulse 0.5s infinite alternate;
}
@keyframes pulse {

    from{
        transform: scale(1);
    } to{
        transform: scale(1.04);
    }
    
}
.btn-up:hover{

    color:#ffffff;
    background-color: #C51F29 !important;

}
.section-left{

    margin-top: 905px;
    padding-left: 80px !important;
}
.section-into{

    margin-top: 130px;
    position: absolute;
    padding-left: 0 !important;
}
.box-picture01{

    padding-left: 0 !important;
    position: relative;
    padding-top: 0 !important;
    box-sizing: border-box !important;
}
.box-picture01 img{

    max-width: 100%;
    height: 680px;
    width: 100%;
    object-fit: cover;
}
.cover{
    background-color: rgba(40, 32, 29, 0.562);
    width: 290px;
    height: 680px;
    position: absolute;
    right: 11px;
    z-index: 11;
    top:20px;
}
.box-into{

    position: absolute;
    margin-top: 80px;
    right: 40px !important;
    z-index: 11;
   
}
.detalhe-into{
    font-size:max(2.5rem,3.2vw);
    font-family: LatoBold;
    color:#fff;
    margin-top: 24px;
}
.barra{
    border:none;
    height: 6px;
    background:linear-gradient(
        to left,
        rgb(244,67,54),
        rgba(40,32,29));
    width: 210px;
    margin: 0;
}
.vetor01{
    max-width: 100%;
    width: 450px !important;
    height: 400px !important;
    object-fit: contain;
    position: absolute;
    right: -400px;
    top:-130px; 

}

.vetor02{
    max-width: 100%;
    width: 250px;
    object-fit: contain;
    position: absolute;
    right:0;
}
.box-desc{
    margin-left: 13vw;
    position: relative;
}
.detalhe-into-2{

    color:#fff;
    font-size: max(16px,1.3vw);
    line-height: 34px;
    font-weight: 300;

}
.btn-link-service{

    margin-top: 32px;
    border:none;
    height: 55px;
    width: 200px;
    background-color: #C51F29 !important;
    color:#fff;
    padding:8px;
    font-size: 15px;

}

.title-service{
    text-transform: uppercase;
}
button{
    transition: 0.8s;
    cursor: pointer;
}
button:hover{

    transform: scale(1.03);
}
.woman-world{
    max-width: 100%;
    width: 500px;
    margin-top: 1100px;
    
}

.title-section{

    font-size:max(2.2rem, 2.2vw);
    color:#000;
    font-weight: bold;

  
}

.describe-section{
    font-size: max(16px, 1.1vw);
    line-height: 28px;
    margin-bottom: 48px;

}
.icone-info{
    max-width: 100%;
    width: 200px;
}
.p-desc{
    margin-top: 8px;
    line-height: 26px;
    font-size: 1.1rem;
}
.title{

    font-size: max(18px, 1.3vw);
    font-weight: bold;
    color:#000;
    margin-bottom: 0;
}
.section-02, .section-03{
    margin-top: 88px;
}

.picture-03{

    max-width: 100%;
}
.box-content{
    padding: 32px 0 0 12px !important;
}

.icon-service{
    max-width: 100%;
    width: 120px;
    margin-bottom: 8px;
  
}
.card-service{

    background-color: #f1f1f1;
    padding:40px 24px 40px 24px;
    height: 310px;
    transition: 0.7s;
}
.card-service:hover{

    border-radius: 0;
    transform: scale(1.05);
    background-color: #201714;
}
.card-service:hover .title-service{
    
    color:#fff;
}
.desc-service{

    font-size: 1vw;
    line-height: 26px;
}
.card-box{
    padding:0 16px 24px 16px !important;
}
.section-004{
    background: url(./cover.png);
    background-size: cover;
    background-attachment: fixed;
    background-repeat: no-repeat;
   /* height: 500px;*/
    padding:40px 80px 90px 80px !important;
}
.title-section-2{
    font-size: 2.5vw;
    color:#fff;
    font-family: Arial;
    font-weight: bold;
}
.describe-section-2{

    color:#fff;
    font-size:max(1.2rem, 1.2vw);
    line-height: 32px;
    margin-bottom: 40px;
    font-weight: 300;
   
}
.box-parceiro{
    height: 200px;
    background-color:#C51F29;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 1s;

}
.box-parceiro:hover{

    transform: scale(1.1);
}
.card-parceiro{
    padding:16px !important;
}
.img-parceiro{
    max-width: 100%;
    width: 200px;
}
.img-c{
    width: 200px;
    height: 150px;
    object-fit: contain;
    border-radius: 12px;
}
.collapsible {
    border:none !important;
}
.box-faq{
    background-color: #f1f1f1;
    padding: 2px 16px 24px 16px;
    margin-bottom: 32px !important;
    cursor: pointer;
    transition: 1s;
}
.box-anwser{
    opacity: 0;
    transition: all 0.5s ease-in-out;
    height: 0;
    overflow: hidden;
}
.ansewer{

    color:#000;
    font-size:max(16px,1.1vw);
    line-height: 32px;
}
.box-anwser.show{

    height: 275px;
    opacity: 1;
}

.box-q-arrow{

      align-items: center;
      transition: 1s;
    
}




.ansewer span{

    margin-left: 32px;
    font-weight: 500;

}
.ansewer i{

    display: block;
    margin-top: 12px;
}
.section-05{

    margin-top:0px;
    margin-bottom: 88px;
}
.title-question{

    font-size:max(1.2rem,1.3vw);
    font-weight: 500;
    color:#000;
    margin-bottom: 2px;
   
}
.btn-open-ansewer{
    border:none;
    font-size: 18px;
    color:#C51F29;
    background-color: transparent !important;
    margin-top: 24px;
}

.section-006{

   
    background:url(./cover2.png);
    background-size: cover;
    background-position: top center;
    padding:0 80px 56px 80px !important;
}
.section-06{

    margin-top: 88px;
}

.input-field input{
    background-color: #fff !important;
    height: 55px !important;
    padding: 0 16px 0 16px !important;
    box-sizing: border-box !important;
    border-radius: 6px !important;
    margin-bottom: 20px !important;
    color:#000 !important;
    border:none !important;
}
.input-field input::placeholder, textarea::placeholder{

    color:#606060;
}

.input-field input:focus {
    border-bottom: 1px solid #fff !important;
    box-shadow: 0 1px 0 0 #fff !important;
    border: none !important;
  }

textarea{
    background-color: #fff !important;
    resize: none;
    border: none;
    height: 200px !important;
    margin-top: 16px !important;
    padding:16px !important;
    border-radius: 6px !important;
    outline: none;
    color:#000 !important;
}
.btn-send{
    margin-top: 40px;
}

.section-007{

    padding:20px 80px 126px 80px !important;
}

.testemunho{
    font-size:max(16px, 1.5vw);
    color:#000;
    line-height: 40px;
}
.testemunho span i{

    margin: 16px !important;
}
.autor{
    font-size: 18px;
    color:#606060;
}

.section-008{

    padding: 0 !important;
    height: 735px;
    background-color: #f1f1f1;
    position: relative;
}
iframe{
    width: 100%;
    border: none;
    height: 735px;
}
.box-endereco {

    position: absolute;
    margin-left: 80px !important;
    margin-top: 98px !important;
    height: 535px;
    background-color: rgba(40, 32, 29, 0.507);
    backdrop-filter:  blur(4px) brightness(60%);
    -webkit-backdrop-filter:  blur(4px) brightness(60%);
    -moz-backdrop-filter:  blur(4px) brightness(60%);
    -ms-backdrop-filter:  blur(4px) brightness(60%);
    -o-backdrop-filter:  blur(4px) brightness(60%);
    padding: 32px 48px 32px 48px !important;
    z-index: 1;

}

.title-section-2{

    font-size: 1.9rem;
    color:#f1f1f1;
    font-weight: bold;
    margin-bottom: 0;
  
}
.title-section-2 i{

    margin-right: 24px;
}
.detalhe-en{
    color:#f1f1f1;
    font-size: 18px;
    line-height: 30px;
    padding-left: 48px;
    font-weight: 300;
    margin-top: 8px;
}

.box-det{
    margin-bottom: 56px;
}
.section-09{
    margin-top: 88px;
   
}
.section-009{

    padding: 0 88px 0 88px !important;
  
}
.limite{

    transition: 0.5s;
   position: relative;
   height: 300px;
}


.card-post{
    padding:14px !important;
    margin-top:32px;
    margin-bottom: 184px;
   
}
.post-cover{
    max-width: 100%;
    width: 100%;
    height: 420px;
    object-fit: cover;
    filter: grayscale(100%);
    transition: 1s;
   
}
.limite:hover .post-cover{

    filter: grayscale(0);
    transform: scale(1.05);
}
.cover-post{
    position: relative;
    overflow: hidden;
    margin-bottom: 64px;
}
.cat-post{
    
    height: 40px;
    width: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #C51F29;
    position: absolute;
    left: -20px;
    top: 50px;
    color:#fff;
    z-index: 1;
}
.title-post{
    color:#000;
    font-size: 1.5em;
    font-weight: 500;
    margin-top: 8px;
    margin-bottom: 0;
}

.title-post a{
    color:#000;
}

.previa-post{
    margin-top: 6px;
}
.previa-post a{
    color:#C51F29;
}
.ver-mais-btn{

    background-color: #f1f1f1 !important;
    border:none;
    height: 50px;
    width: 200px;
    color:#000;
    margin-top: 64px;
    transition: 1s;
    margin-bottom: 40px;
}
.ver-mais-btn:hover{

    background-color: #C51F29 !important;
    color:#fff;
}
.det-post{

    height: 160px;
    background-color:rgba(255, 255, 255, 0.753);
    width:max(270px, 24vw);
    position: absolute;
    left: 20px;
    /*right: 0;
    margin-left: auto;
    margin-right: auto;*/
    bottom: -100px;
    padding: 12px 24px 12px 24px;
    border-radius: 4px;
    box-shadow: 0 0 12px rgba(59, 59, 59, 0.308);
    transition: 0.5s;
   
}
.det-post:hover{

    height: 170px;
    background-color:#ffffff;
}

.det-post:hover .title-post a{

   cursor: pointer;
   color: #660f15 ;
}

.show-test{

    position: absolute;
    height: 300px;
    width: 300px;
    background-color: blue;
    z-index: 99;
}

.show-test-2{
    position: absolute;
    height: 300px;
    width: 300px;
    background-color: red;
    z-index: 99;
}

.preloader{
    background-color: #fff;
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;
   

}

.exitPreloader{
    animation: exit 2s ease-in-out forwards;
    animation-delay: 2s;
}

@keyframes exit {

    from{

        opacity: 1;
        z-index: 9999;
    
    }

    to{

        opacity: 0;
        z-index: -9999;
        display: none;
    }
    
}

.img-preloader{
    max-width: 100%;
    width: 300px;
}

@media all and (max-width:500px) {

    .btn-up{
        height: 50px;
        width: 50px;
        right: 15px;
    }

    .reset{
        padding-left: 24px !important;
        padding-right: 24px !important;
    }
    .box-anwser.show{

        height: 375px;
        opacity: 1;
    }
    .testemunho{
      
        line-height: 32px;
    }
    .section-left{

        margin-top: 905px;
        padding-left: 80px !important;
    }

    .section-004{
    
        padding:40px 24px 90px 24px !important;
    }
    .section-006{

   
        background:url(./cover2.png);
        background-size: cover;
        background-position: top center;
        padding:0 80px 56px 80px !important;
    }
    .section-06{
    
        margin-top: 88px;
    }
    .section-007{

        padding:20px 24px 126px 24px !important;
    }
    .section-09{
        margin-top: 88px;
       
    }
    .section-009{
    
        padding: 0 24px 0 24px !important;
      
    }

    .box-endereco {

        margin-left: 0 !important;
        padding: 32px 24px 32px 24px !important;
        height: 460px;
    
    }

    .title-section-2{
        font-size: max(18px,2.5vw);
    }



.section-right{
    
    height: 400px;
    padding:0 32px 0 32px !important;
}

.woman-world{
    max-width: 100%;
    width: 500px;
    margin-top: 100px;
    
}
.section-left{

    margin-top: 105px;
    padding-left: 24px !important;
}

.section-into{

    margin-top: 0px;
    position: relative;
    padding: 0 !important;
    background-color: #201714;
    height: 750px;
}
.cover{
   
    width: 100%;
    height: 400px;
    position: absolute;
    z-index: 11;
    top:20px;
    right: 0 !important;
}

.box-picture01{

    padding: 0 !important;
    position: relative;
    box-sizing: border-box !important;
}
.box-picture01 img{

    max-width: 100%;
    height: 400px;
    width: 100%;
    object-fit: cover;
}

.box-into{

    position: absolute;
    margin-top: 80px;
    right: 0px !important;
    z-index: 11;
    padding-left:24px !important
   
}

.box-desc{
    margin-left: 0;
    position: relative;
    padding-top: 24px !important;
}


.barra{
    width: 100px;
}
    .card-service{

        background-color: #f1f1f1;
        padding:40px 24px 40px 24px;
        height: 250px;
        transition: 0.7s;
    }

    .icon-service{
        width: 75px;
        margin-bottom: 4px;
      
    }

    .section-faq-1{
        padding: 0 !important;
        
    }

    .detalhe-en{
      
        font-size: 16px;
        padding-left: 36px;
       
    }

    .det-post{

      
        background-color:rgba(255, 255, 255, 0.753);
        width:95%;
        position: absolute;
        left: 8px;
        right: 2px;
      
       
    }
}

@media all and (max-width:350px) {


    .section-into{
        height: 750px;
    }

    .detalhe-into{
        font-size:max(2.4rem,3.2vw);
    }

    .box-desc{
        padding-top: 40px !important;
    }

    .woman-world{
        max-width: 100%;
        width: 500px;
        margin-top: 120px;
        
    }

    .det-post{

        height: 130px;
        width:max(250px, 24vw);
        bottom: -60px;
        padding: 8px 16px 12px 16px;
        border-radius: 0;
       
       
    }

    .title-post{
      
        font-size: 1.3em;
    
    }
    

  
}

@media all and (max-width:340px) {


    .section-into{
        height: 750px;
    }

    .detalhe-into{
        font-size:max(2.3rem,3.2vw);
    }

    .box-desc{
        padding-top: 40px !important;
    }
    .box-desc{
        padding-top: 48px !important;
    }

    .box-endereco {

        margin-left: 0 !important;
        padding: 32px 16px 32px 16px !important;

    
    }

    .det-post{

        height: 130px;
        width:max(250px, 24vw);
        padding: 8px 16px 12px 16px;
        border-radius: 0;
       
       
    }

  
}

@media all and (max-width:327px) {


    .section-into{
        height: 750px;
    }

    .detalhe-into{
        font-size:max(2.3rem,3.2vw);
    }

  
    .box-desc{
        padding-top: 24px !important;
    }

    .box-endereco {

        margin-left: 0 !important;
        padding: 32px 16px 32px 16px !important;
        height: 520px;

    
    }

    .det-post{

        height: 140px;
        width:max(220px, 24vw);
        padding: 8px 16px 12px 16px;
        border-radius: 0;
        bottom: -50px;
       
       
    }

    .box-picture01 img{

        max-width: 100%;
        height: 430px;
        width: 100%;
        object-fit: cover;
    }
    .cover{
      
        height: 430px;
       
    }

    .woman-world{
        max-width: 100%;
        width: 500px;
        margin-top: 150px;
        
    }

  
}